<template>
  <div>
    <div class="nau-navBar-header">
      <van-nav-bar
          :title="title"
          left-text="返回"
          right-text=""
          left-arrow
          @click-left="onBack"
      />
    </div>
    <div style="margin-top: 44px;">
      <van-pull-refresh v-if="!isError" v-model="refreshing" @refresh="onRefresh">
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text=""
            @load="onLoad"
        >
          <van-cell v-for="item in list"
                    :icon="item.imgUrl"
                    :key="item.id"
                    :title="item.title"
                    @click="toInfo(item)" is-link/>
        </van-list>
      </van-pull-refresh>
      <van-empty v-if="isError" :description="errorMsg" />
      <van-empty v-if="list.length == 0 && !isError" description="暂无相关内容！"/>
    </div>
  </div>
</template>

<script>
import {getHelpList} from "../../api/other";

export default {
  name: "help",
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      page: 1,
      size: 15,
      total:0,
      totalPage:0,
      list: [],
      errorMsg:'',
      isError:false,
      title: '帮助',
      orgCode: '1'
    }
  },
  created() {
    //this.getList()
    //this.isShow = true
  },
  methods: {
    onBack() {
      this.$router.go(-1);
    },
    getList(){
      const that = this
      that.loading = true
      getHelpList(that.orgCode).then(response => {
        console.log(response)
        this.loading = false
        if (that.refreshing) that.refreshing = false
        if (response.code == process.env.VUE_APP_SUCCESS_CODE){
          if (that.page == 1) {
            that.list = response.data
          }else{
            const data = response.data
            if (data && data.length > 0){
              data.forEach(item => {
                that.list.push(item)
              })
            }
          }
          this.totalPage = response.totalPage
          if(that.page >= response.totalPage){
            that.finished = true
          }
        }else{
          that.isError = true
          that.errorMsg = response.message
        }
      }).catch(() => {
        this.loading = false
        that.finished = true
        if (that.refreshing) that.refreshing = false
        that.isError = true
        that.errorMsg = '请求网络出错，请稍后再试!'
      })
    },
    onLoad() {
      if (this.list.length == 0){
        this.page = 1
        this.list = []
        this.getList()
      }else{
        if (this.page < this.totalPage){
          this.page += 1
          this.getList()
        }else{
          this.finished = true
        }
      }
      console.log(this.page)
    },
    onRefresh() {
      this.finished = false
      this.page = 1
      this.totalPage = 0
      this.total = 0
      this.list = []
      this.onLoad()
    },
    toInfo(e){
      console.log(e)
      this.$router.push('helpDetail?id='+e.id+"&title="+e.title)
    }
  }
}
</script>

<style scoped>

</style>