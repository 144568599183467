import request from '../utils/request'
import qs from "qs";
// import qs from 'qs'

/**
 * 获取上传示例
 * @param page
 * @param pageSize
 * @param id
 * @returns {*}
 */
export function uploadDemo(key){
    return request({
        url: 'other/uploadDemo.do?key='+key,
        method: 'get'
    })
}
/**
 * 获取上传示例
 * @param page
 * @param pageSize
 * @param id
 * @returns {*}
 */
export function upload(content,suffix){
    return request({
        url: 'other/uploadFile.do',
        method: 'post',
        data: qs.stringify({content:content,suffix:suffix})
    })
}

/**
 * 删除文件
 * @param id
 */
export function deleteFile(id){
    return request({
        url: 'other/deleteFile.do',
        method: 'post',
        data: qs.stringify({id:id})
    })
}

/**
 * 获取内容帮助列表
 * @param orgCode
 * @returns {*}
 */
export function getHelpList(orgCode){
    return request({
        url: 'other/help.do',
        method: 'post',
        data: qs.stringify({orgCode:orgCode})
    })
}

/**
 * 获取帮助详情
 * @param id
 * @returns {*}
 */
export function getHelpDetail(id){
    return request({
        url: 'other/helpDetail.do',
        method: 'post',
        data: qs.stringify({id:id})
    })
}